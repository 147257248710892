module.exports = {
  pathPrefix: "/",
  siteUrl: "https://www.bigcitylab.com",
  siteTitle: "Big City Lab",
  siteIndieDescr: "Indie game studio",
  siteCasualDescr: "Casual game studio",
  author: "Antelope Studio",
  postsForArchivePage: 10,
  defaultLanguage: "en",
  disqusScript: "https://luigi-colella.disqus.com/embed.js",
  pages: {
    home: "/",
    indie: "indie",
    casual: "casual",
    feedback: "feedback",
    about: "about",
  },
  social: {
    reddit: "https://www.reddit.com/user/bigcitylab",
    discord: "https://discord.gg/HY4XWzwqAm",
    twitter: "https://twitter.com/BIGCITYLABgames",
    facebook: "https://www.facebook.com/BigCityLabGames",
    youtube: "https://www.youtube.com/channel/UCAYuUhq0PC45BRjR4YJtG4Q",
    steam: "https://steamcommunity.com/groups/BigCityLab",
  },
  tags: {},
};
