/* Vendor imports */
import React from "react";
/* App imports */
import style from "./footer.module.less";

const Footer = () => (
  <div className={style.container}>
    <p>
      © Production and Copyright - Big City Lab LLC, 2018–2023 - AK23 LTD,
      2022-2023.
    </p>
  </div>
);

export default Footer;
